import React from 'react';
import styled from 'styled-components';
import { Provider as ThemeProvider, Relative, Absolute, Code } from 'rebass';

import Paths from '../Head/Paths';

const Outer = styled(Relative)`
  padding-bottom: 4rem;
  margin: 0 auto;
  text-align: center;
  min-height: 100vh;
`;

const Inner = styled(Relative)`
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
`;

const Content = styled(Code)`
  white-space: pre;
`;

const Bottom = styled(Absolute)`
  bottom: 0;
  left: 0;
  right: 0;
`;

const App = (props) => {
  const year = Number(new Date().getFullYear());
  return (
    <ThemeProvider
      theme={{
        fonts: {
          sans: 'Futura, sans-serif',
          mono: "'Fira Mono', monospace",
        },
      }}
    >
      <Outer>
        <Inner>
          <Paths y={7} />
        </Inner>
        <Relative px={[2, 4]} my={4}>
          <Inner>
            <Content fontSize={[2, 3]}>
              {`
Juhani Pelli
····
+358503006152
juhani@pelli.fi
··
· Developer w/ an eye for design
· <3 Clean Code
· ${year - 1995} years since first project
· Music Lover
· Vim ---> Vscode
· ${year - 2005} years of commercial experience
· Maintainable, expressive & simple
· ${year - 2010} years building modern web applications
· Scout model, DRY, YAGNI
· Workhorse
· ${year - 2020} years focused on full stack development
· Relentless attention to detail
`}
            </Content>
          </Inner>
        </Relative>
        <Bottom>
          <Inner>
            <Paths y={3} />
          </Inner>
        </Bottom>
      </Outer>
    </ThemeProvider>
  );
};

export default App;
